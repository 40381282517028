.pagination{
    *{
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    font-family:arial;
    }
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  li{
    display: inline;
    text-align: center;
  }
  a{
    float: left;
    display: block;
    font-size: 14px;
    text-decoration: none;
    padding: 5px 12px;
    color:$primary-white;
    margin-left: -1px;
    border:1px solid transparent;
    line-height: 1.5;
    &.active{  cursor: default;}
    &:active{ outline: none;}
  }
}
.view-1{
  li{
    &:first-child{
      a{ 
        -moz-border-radius: 6px 0 0 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px 0 0 6px;
      }
    }
    &:last-child{
      a{
        -moz-border-radius: 0 6px 6px 0;
        -webkit-border-radius: 0;
        border-radius: 0 6px 6px 0;
      }
    }
  }
  a{
    border-color:$primary-gray; 
    color:$primary-denim;
    background:$primary-white;
    &:hover{
      background:$primary-gray;
    }
    &.active, &:active{
      border-color: $primary-denim;
      background:$primary-denim;
      color:$primary-white;
    }
  } 
}
.view-2{
  li{
    &:first-child{
      a{ 
      -moz-border-radius: 50px 0 0 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px 0 0 50px;
      }
    }
    &:last-child{
      a{
        -moz-border-radius: 0 50px 50px 0;
        -webkit-border-radius: 0;
        border-radius: 0 50px 50px 0;
      }
    }
  }
  a{
    border-color:$primary-gray; 
    color:#999;
    background:$primary-white;
    &:hover{
      color:$primary-red;
      background-color: #eee;
    }
    &.active, &:active{
      border-color: $primary-red;
      background:$primary-red;
      color:$primary-white;
    }
  } 
}
.view-3{
   a{
      margin:0 5px;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%;
      background-color: #F7C12C;
     &.prev{
       -moz-border-radius: 50px 0 0 50px;
       -webkit-border-radius: 50px;
       border-radius: 50px 0 0 50px;
       width:100px;
     }
     &.next{
       -moz-border-radius: 0 50px 50px 0;
       -webkit-border-radius: 0;
       border-radius: 0 50px 50px 0;
       width:100px;
     }
      &:hover{
        background-color:#FFA500;
      }
       &.active, &:active{
         background-color:#FFA100;
       }
    }
}

