@import "default", "variables", "print", "iphone_only", "mobile-swipeable-list";

@import "chat-card", "header", "messages", "emoji", "slider", "converstation",
  "pagination", "customersearch", "settings-drawer", "moa", "breadcrumb",
  "animate", "options", "image_loading", "accessibility", "signup",
  "hide_button", "delete_button", "filter-options";

/* --SF Connect Margins-- */
.-sfc {
  &-mt-10 {
    margin-top: 10px;
    &-small {
      margin-top: 0px;
    }
  }
  &-mt-20 {
    margin-top: 20px;
  }
  &-center-on-page {
    display: inline-flex;
    padding: 0;
    margin: 0;
    /* note: center of the page */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* --SF Connect Margins - Mobile-- */
@media (max-width: $grid-breakpoint-lg) {
  .-sfc {
    &-mt-10 {
      margin-top: 0px;
      &-small {
        margin-top: 10px;
      }
    }
  }
}

/* --Disables Links-- */
a.disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

/* --Accessibility-- */
.clipped-text,
.hide-offscreen {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.-display-flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.-display-flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* --oneX Overrides-- */
.-oneX-page-loader {
  .-oneX-loaderdiv {
    display: block;
  }
}

.-oneX-icon-container.-sfc-oneX-overide {
  position: relative;
  .-oneX-icon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*overide 1x oneX container for larger screens */
@mixin media_min_width($upto_width: 2000) {
  $base-counter: $upto_width/100;

  @for $i from 13 through $base-counter {
    $min_width: $i * 100px;
    @media (min-width: $min_width) {
      .-oneX-container {
        width: $min_width;
      }
    }
  }
}

.associate-initial-section {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: $primary-merna-02;
  margin-left: 25px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  color: $primary-white;
  word-spacing: 5px;
  position: relative;
  span {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.virtual-assistant-section {
  display: inline-flex;
  width: fit-content;
  position: relative;
  padding: 5px;
  height: 25px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  span {
    border-radius: 15px;
    color: white;
    background-color: $primary-red;
    margin-right: 5px;
    margin-top: -2.5px;
    padding: 2px;
    padding-right: 7px;
    padding-left: 7px;
  }
}

.vaInfo-icon-background {
  display: inline-block;
  position: relative;
  .vaInfo {
    height: 18px;
    width: 18px;
    display: inline-block;
    position: relative;
    left: 2px;
    top: 0px;
    font-weight: bold;
    font-size: 12px;
  }
  &:hover ~ .vaInfoText {
    display: block;
  }
}

.vaInfoText {
  display: none;
  position: absolute;
  z-index: 100;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  background-color: $primary-red;
  margin-right: 5px;
  margin-top: -2.5px;
  padding: 2px;
  padding-right: 7px;
  padding-left: 7px;
  max-width: 250px;
  transform: translate(109%, -20%);
  &:hover {
    display: block;
  }
  a {
    color: white !important;
    text-decoration: none !important;
    font-weight: bold;
  }
}

.oooAndVAInfoText {
  transform: translate(160%, -20%) !important;
}

.protected-person-label {
  display: inline-flex;
  width: fit-content;
  position: unset;
  height: 25px;
  border-radius: 7px;
  color: white;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.preview-div {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 32px;
}

@include media_min_width();
