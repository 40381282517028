$scrollbarBG: #cfd8dc;
$thumbBG: #90a4ae;
.cancel-container {
    width: 25%;
}

.search-criteria-container {
    // background:#f3f0f0;
    background:$primary-white;
    padding: 10px;
    border-bottom: 1px solid $primary-gray;

    .dropdown-row {
        padding:15px;
    }
    .exact-match{
        padding: 10px 0;
      
        .exact-match-checkbox {
            color: black;
            font-weight: bold;
        }
    }
    .customer-search-button{
        padding: 0px;
        margin: auto;
    }
}
.mobile-input{
    background:#f3f0f0;
    padding: 10px;
    .phone-number-field{
        padding-top:0px;
        position: absolute;
    }
   
}
.center-container-outer {
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, 200%);
-ms-transform: translate(-50%, 200%); /* for IE 9 */
-webkit-transform: translate(-50%, 200%); /* for Safari */

/* optional size in px or %: */
width: 100px;
height: 100px
}

.center-container-inner {
    padding: 0;
    margin: 0;
  
    /* note: center of the page */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* end: center of the page */
}


.-oneX-table--standard {
      margin-bottom: 20px;
      overflow-y: scroll;
      max-height: 63vh;
      &::-webkit-scrollbar {
        width: 11px;
      }
      & {
        scrollbar-width: thin;
        scrollbar-color: $thumbBG $scrollbarBG;
      }
      &::-webkit-scrollbar-track {
        background: $scrollbarBG;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $thumbBG;
        border-radius: 6px;
        border: 3px solid $scrollbarBG;
      }
    > div.-oneX-thead > .-oneX-th--col:nth-child(1){
        width: 25%;
        @media screen and (max-width:988px){
            width: 50%;;
        }
    }
    .-oneX-tr{
        .-oneX-icon-container div:first-child {border: none;}
        .-oneX-row:first-child {border: none;}
        div:first-child {border: none ! important;}
        &:nth-child(2n) {
            background-color: #f3f3f3;
        }
        .slider-row {
            > fieldset {
                width: 100px;
                @media screen and (max-width:575px){
                    margin: 10px;
                }
            }
        }
    }
}


@include media_iPhone('X') {  
    @include customerSearchFix()
}
@include media_iPhone('678') {  
    @include customerSearchFix()
}