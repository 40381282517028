$navbar-base-color: #333;
$navbar-text-color: #FFF;
$navbar-highlight-color: rgb(230, 22, 22);

.word-track-content{
  ul{
    padding: 0;
    li{
      padding: 20px;
      display: block;
      min-height:100px;
      border-bottom: 1px solid black;
      &:hover {
        background-color: #ddd;
        border: 1px solid black;
      }
      &:after {
        content: "[Select]";
        float: right;
        padding-left: 10px;
        position: relative;
        top: -10px;
      }
      strong {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
}