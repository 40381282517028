@mixin customerSearchFix() {
    .search-criteria-container{
        .-oneX-textfield--floating-input, .-oneX-textfield__input {
            width: 135%;
        }
        .-oneX-textfield__floatingLabel[for="customeFirstName"],
        .-oneX-textfield--floating-input[id="customeFirstName"] {
            margin-top: 10px;
        
        } 
        .-oneX-textfield__floatingLabel[for="customerLastName"]{
        width: fit-content;
        }
        
        .-oneX-widget .-oneX-formatWrapper {
            width: fit-content;
            }
        
        button.-oneX-btn-primary{
            margin-top: 10px;
        }
    }
}

@mixin sendMessageFix() {
    .send-message {
        .send-message-pane {
            margin: 20px -20px;
            min-height: 600px;
            max-height: 700px;
        }
    }
}