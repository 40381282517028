$primary-red: #e22925;
$primary-denim: #0a70bc;
$primary-cerulean: #0863a6;
$primary-prussian-blue: #003a65;
$primary-gray: #313131, #f1b4b4, #6e6e6e, #8e8e8e, #adadad, #cbcbcb, #e6e6e6, #f3f3f3;
$primary-white: #ffffff;
$primary-black: #000000;
$primary-transparent: #ffffff00;

/* ------- Start: New SF Brand Evolution Colors ------- */
$primary-merna-01: #701611;             //.-oneX-primary-merna-01
$primary-merna-02: #cb2016;             //.-oneX-primary-merna-02
$primary-merna-03-digital: #e81e1e;     //.-oneX-primary-merna-03-digital
$primary-merna-03: #fc2929;             //.-oneX-primary-merna-03  -- preferred SF Red
$primary-merna-04: #ef756c;             //.-oneX-primary-merna-04

$primary-charcoal-01-digital: #2d2929;  //.-oneX-primary-charcoal-01-digital
$primary-charcoal-02: #403c3c;          //.-oneX-primary-charcoal-02

$primary-gray-01-digital: #767676;      //.-oneX-primary-gray-01-digital
$primary-gray-01: #7f7e7d;              //.-oneX-primary-gray-01
$primary-gray-02: #acabab;              //.-oneX-primary-gray-02
$primary-gray-03: #e4e3e3;              //.-oneX-primary-gray-03
$primary-gray-04-digital: #f4f3f3;      //.-oneX-primary-gray-04-digital

$primary-khaki-01: #b39462;             //.-oneX-primary-khaki-01
$primary-khaki-02: #debc85;             //.-oneX-primary-khaki-02
$primary-khaki-03: #f2ddbb;             //.-oneX-primary-khaki-03
$primary-khaki-04: #f7f0e4;             //.-oneX-primary-khaki-04

$accent-heritage-gold: #996100, #da8a00, #f1aa1b, #f1c479;
$accent-life-green: #1a7558, #239873, #0eb275, #85d0ac;
$accent-digital-blue: #00476b, #006191, #0073a8, #89bed9;
/* ------- End: New SF Brand Evolution Colors ------- */


$accent-red: #c31820, #e22925, #e85c54, #ef928d, #f7c8c6;
$accent-persimmon: #db4710, #f25214, #f57d4e, #f8a889, #fbd3c4;
$accent-tangerine: #eb7302, #ff7c00, #ff9c3f, #ffbd7f, #ffdebf;
$accent-turbo: #e9b918, #ffce17, #ffd753, #ffe48c, #fff1c5;
$accent-yellow: #e3de06, #fff800, #fff93F, #fffb7f, #fffdbf;
$accent-inchworm: #92cd0d, #a9e71c, #beec54, #d3f38d, #e9f8c6;
$accent-malachite: #00cc36, #00e13c, #3fe86c, #7fef9d, #bff7ce;
$accent-caribbeangreen: #01b391, #00cca5, #3fd8bb, #7fe5d2, #bff2e8;
$accent-deepskyblue: #029fdb, #00b9ff, #3fcaff, #7fdcff, #bfedff;
$accent-mediumslateblue: #5f4ee0, #6f5df7, #9285f9, #b7adfb, #dbd6fd;
$accent-electricpurple: #a002cd, #be00f3, #ce3ff6, #de7ff8, #eebffb;
$accent-mediumvioletred: #b10f6f, #d01584, #db4fa2, #e789c1, #f3c4e0;

/* Notification Colors */
$primary-informational: nth($accent-deepskyblue, 1);
$primary-confirmation: #01A789;
$primary-warning: nth($accent-tangerine, 1);
$primary-error: nth($accent-red, 1);

/* Button Colors */
$button-active-color: $primary-black;

/* Font Defaults */
$primary-font-size: 16;
$primary-font-line-height: 24;

$grid-breakpoint-xs : 0;
$grid-breakpoint-sm : 576px;
$grid-breakpoint-md : 768px;
$grid-breakpoint-lg : 992px;
$grid-breakpoint-xl : 1200px;

$padding-sm: calc((100% - 540px)/2) ;
$padding-md: calc((100% - 720px)/2);
$padding-lg: calc((100% - 930px)/2) ;
$padding-xl: calc((100% - 1110px)/2);

$primary-grouped-input-size: 22;

// Border States
$border-color-default: nth($primary-gray,3);
$border-color-hover: nth($primary-gray,3);
$border-color-focus: $primary-denim;
$border-color-error: nth($accent-red, 1);
$border-color-disabled: nth($primary-gray, 6);

// iPhone  Media Queries
@mixin media_iPhone($type:'X', $orientation:'both') {
    //  ******** Defaults ******** 
    //  -- $type = X ['X' | '678' | '678plus']
    //  -- $orientation = both ['landscape' | 'portrait']
    // ******** Example Usage ******** 
    // @include media_iPhone() { /*Add Styles*/ }
    // @include media_iPhone('X') { /*Add Styles*/ }
    // @include media_iPhone('678plus') { /*Add Styles*/ }
    // @include media_iPhone('678') { /*Add Styles*/ }
    // @include media_iPhone('X', 'landscape') { /*Add Styles*/ }
    // @include media_iPhone('678plus','landscape') { /*Add Styles*/ }
    // @include media_iPhone('678','landscape') { /*Add Styles*/ }
    // @include media_iPhone('X', 'portrait') { /*Add Styles*/ }
    // @include media_iPhone('678plus','portrait') { /*Add Styles*/ }
    // @include media_iPhone('678','portrait') { /*Add Styles*/ }

    $media_iPhone_X: 375px, 812px, 3;
    $media_iPhone_678: 375px, 667px, 0;
    $media_iPhone_678plus: 414px, 736px, 0;
    $media_values: $media_iPhone_X;

    @if $type == 'X' { $media_values: $media_iPhone_X }
    @else if $type == '678' { $media_values: $media_iPhone_678 }
    @else if $type == '678plus' { $media_values: $media_iPhone_678plus }

    @if ($orientation == 'both' and nth($media_values, 3) == 0) {
        @media screen and (min-device-width : nth($media_values, 1)) 
                      and (max-device-width : nth($media_values, 2)) {
            @content;
        }
    } @else {
        @if ($orientation == 'both'and nth($media_values, 3) > 0) {
            @media screen and (min-device-width : nth($media_values, 1)) 
                        and (max-device-width : nth($media_values, 2)) 
                        and (-webkit-device-pixel-ratio : nth($media_values, 3)){
                @content;
            }
        } @else {
            @if ($orientation != 'both' and nth($media_values, 3) > 0) {
                @media screen and (min-device-width : nth($media_values, 1)) 
                            and (max-device-width : nth($media_values, 2)) 
                            and (-webkit-device-pixel-ratio : nth($media_values, 3))
                            and (orientation : $orientation) {
                    @content;
                }
            } @else {
                @media screen and (min-device-width : nth($media_values, 1)) 
                            and (max-device-width : nth($media_values, 2)) 
                            and (orientation : $orientation) {
                    @content;
                }
            }
        }
    }
}