footer {
    position:fixed;
    left:0;
    bottom:0;
    right:0;
    background-color: white;
    border-top: 1px solid;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    height: 20px;
    width:100%;
    transition: height 3.0s ease;
    z-index: 2;
    &:hover,
    &:focus-within{
      height: 60px;
      padding-top: 10px;
    }

    .-oneX-col {
        text-align: center;
        .copyright-text {
          font-size: 12px;
          line-height: 16px;
          padding-bottom: 8px;
          @media screen and (max-width: 988px) {
            font-size: 10px;
            line-height: 12px;
            padding-bottom: 6px;
          }
      }
    }
    @media screen and (min-device-width : 300px) and (max-device-width : 812px) {
      transform: translate3d(0, 100%, 0);
    }
  }
