.signup-page {
    padding: 4%;
    margin: auto; 
    .-oneX-notification .-oneX-notification__text {
    border: $primary-warning solid 1px;
    padding: 10px;
      .-sfc-mt-10 {
        margin-top: 10px;
      }
    }
    img {
      max-width:100%;
      height:auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      position: relative;
  }
    .-sfc-logo{
      position: relative;
      bottom: 50%;
      left: 5%
    }
    .refresh-button {
      margin-left: 35%;
      width: 30%;
    }
  }
  
  @media screen and (min-device-width : 100px ) and (max-device-width : 812px) {
    .signup-page {
      margin: 15px;
        .-oneX-notification[type="confirmation"] .-oneX-notification__text {
          border: $primary-confirmation solid 1px;
        }
        .-sfc-logo{
          background-size: 50px 50px;
          width: 50px;
          height: 50px;
        }
        .activate-button {
          //margin-top: 10px;
          width: 300px;
        }
        .refresh-button {
          margin: 0px;
          width: 100%;
        }
        
    } 
  }