// body {
//     /* prettier-ignore */
//     font-family: system, -apple-system, '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
//     line-height: 1.7;
//     margin: 0;
//   }
//   
//   .application {
//     display: flex;
//     flex-direction: column;
//     height: 100vh;
//   }
  
//   .page-content {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     height: 100%;
//     min-height: 100%;
//     overflow-y: auto;
//     -webkit-overflow-scrolling: touch;
//   }
  
//   .page__action--title {
//     padding: 0 8px;
//     font-weight: 600;
//     font-size: 14px;
//   }
  
//   .page__action--value {
//     font-size: 12px;
//     padding-bottom: 4px;
//   }
  
//   .page__select {
//     height: 32px;
//     padding: 4px;
//     margin: 8px 16px;
//     font-size: 16px;
//     align-self: stretch;
//   }
  
//   .page__select:focus {
//     outline: none;
//   }
  
//   .page__button {
//     height: 32px;
//     padding: 4px 8px;
//     margin: 8px 16px;
//     font-size: 16px;
//   }
  
//   .page__summary {
//     padding: 16px 0;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
  
  @media screen and (min-device-width: 1200px) {
    /* The device with borders */
    .smartphone {
      position: relative;
      width: 360px;
      height: 640px;
      margin: auto;
      border: 16px black solid;
      border-top-width: 60px;
      border-bottom-width: 60px;
      border-radius: 36px;
    }
  
    /* The horizontal line on the top of the device */
    .smartphone::before {
      content: '';
      display: block;
      width: 60px;
      height: 5px;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #333;
      border-radius: 10px;
    }
  
    /* The circle on the bottom of the device */
    .smartphone::after {
      content: '';
      display: block;
      width: 35px;
      height: 35px;
      position: absolute;
      left: 50%;
      bottom: -65px;
      transform: translate(-50%, -50%);
      background: #333;
      border-radius: 50%;
    }
  
    /* The screen (or content) of the device */
    .smartphone__content {
      width: 360px;
      height: 640px;
      background: white;
    }
  
    .smartphone__footer {
      padding: 56px 0;
      margin-top: unset;
    }
  }


  .swipeable-list {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .swipeable-list-item {
    position: relative;
    transition: max-height 0.5s ease;
    max-height: 1000px;
    transform-origin: top;
    overflow: hidden;
    width: 100%;
    z-index: 0;
  }
  
  .swipeable-list-item__content {
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    height: 100%;
    display: flex;
  }
  
  .swipeable-list-item__content--return {
    transition: transform 0.5s ease-out;
  }
  
  .swipeable-list-item__content--remove {
    transition: transform 0.35s ease-in;
  }
  
  .swipeable-list-item__content-right {
    background-color: $primary-warning;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    opacity: 0;
    div { 
        padding: 10px 30px;
        color: white; 
    }
  }
  
  .swipeable-list-item__content-right--return {
    transition: opacity 0.5s ease-out;
  }
  
  .swipeable-list-item__content-left {
    background-color: $primary-error;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    opacity: 0;
    justify-content: flex-end;
    div { 
        padding: 10px 30px;
        color: white;
     }
  }
  
  .swipeable-list-item__content-left--return {
    transition: opacity 0.5s ease-out;
  }
  .basic-swipeable-list__item {
    border-bottom: 1px solid #c4c4c4;
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
  }
  .swipeable-list__item-content-left,
  .basic-swipeable-list__item-content-left {
    background-color: red;
    box-sizing: border-box;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 8px;
  }
  .swipeable-list__item-content-right,
  .basic-swipeable-list__item-content-right {
    background-color: green;
    box-sizing: border-box;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    justify-content: flex-end;
  }
  .basic-swipeable-list__container {
    width: 100%;
    margin: 24px 0;
    outline-color: gray;
    outline-style: solid;
    outline-width: 1px 0;
  }
  .complex-swipeable-list__item {
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid #c4c4c4;
    flex: 1;
    min-width: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
  }
  
  .complex-swipeable-list__item-label {
    display: flex;
    align-items: center;
  }
  
  .complex-swipeable-list__item-icon {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    fill: #083749;
  }
  
  .complex-swipeable-list__item-name {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: #083749;
    margin-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .complex-swipeable-list__item-description {
    margin-left: 2px;
    margin-top: 4px;
    color: #959595;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .complex-swipeable-list__item-content-left {
    background-color: red;
    color: white;
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  
  .complex-swipeable-list__item-content-right {
    background-color: green;
    color: white;
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .complex-swipeable-list__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    width: 64px;
    max-width: 64px;
    text-align: center;
    padding: 4px;
    padding-top: 8px;
  }
  
  .complex-swipeable-list__icon {
    fill: white;
    width: 32px;
    height: 32px;
    margin-top: -4px;
  }
  .complex-swipeable-list__container {
    width: 100%;
    height: 180px;
    min-height: 180px;
    outline-color: gray;
    outline-style: solid;
    outline-width: 1px 0;
    margin: 24px 0;
  }
 .size-to-content-swipeable-list__container {
    width: 100%;
    margin: 24px 0;
    outline-color: gray;
    outline-style: solid;
    outline-width: 1px 0;
  }

 .animations-swipeable-list__container {
    width: 100%;
    margin: 24px 0;
    outline-color: gray;
    outline-style: solid;
    outline-width: 1px 0;
  }
  
  .animations__switcher {
    align-self: auto;
  }
  
  .animations__switcher-row {
    padding-top: 8px;
  }
  
  .my-node-exit {
    opacity: 1;
  }
  
  .my-node-exit-active {
    opacity: 0;
    max-height: 0;
    transition: max-height 1000ms, opacity 1500ms;
  }
  
  .my-node-enter {
    opacity: 0;
    max-height: 0;
  }
  
  .my-node-enter-active {
    opacity: 1;
    max-height: 1000px;
    transition: max-height 4000ms, opacity 1500ms;
  }
  .styled-swipeable-list__container {
    width: 100%;
    margin: 24px 0;
    outline-color: gray;
    outline-style: solid;
    outline-width: 1px 0;
  }
  
  .styled-swipeable-list {
    height: 320px;
    background-color: cornsilk;
  }
