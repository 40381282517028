.bread-crumb {
    height: 43px; 
    cursor: pointer;
    .title{
        display: inline;
        padding: 10px 6px 10px 28px;
        font-family: SourceSansPro-SemiBold;
        font-size: 14px;
        font-weight: bold;
        color: $primary-denim;
        letter-spacing: 0;
        text-align: left;
        line-height: 21px;
        @media screen and (max-width:$grid-breakpoint-sm ) {
            /*Extra Small <576*/
        }
        
        @media screen and (min-width:$grid-breakpoint-md ) and (max-width:$grid-breakpoint-xl) {
            /* Medium >= 768 */
            &:last-child {
                font-family: SourceSansPro-Regular;
                color: nth($primary-gray, 3);
            }
        }
        
        @media screen and (min-width:$grid-breakpoint-xl) { 
            /* Extra Large >=1200 */
            &:last-child {
                font-family: SourceSansPro-Regular;
                color: nth($primary-gray, 3);
            }
        }
    }
    .-oneX-icon-container {
        position: relative;
        top: -14px;
        display: inline-block;
    }

    .-oneX-icon .icon {
        fill: $primary-denim !important;
    }
}