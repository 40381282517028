/*override 1x background */
.-oneX-icon-container .-oneX-icon svg .background * {
  fill: $primary-transparent;
}

.chevronDiv {
  border: none;
}

.officeSettingsHeaderBkGround {
  background-color: $primary-red;
  height: 100px;
  .-oneX-icon .icon {
    fill: $primary-white;
  }
}

.textTemplatesHeaderBkGround {
  background-color: $primary-red;
  height: 155px;
}

.oneX-icon-container
  .-oneX-icon--interactive
  .officeSettingsHeaderBkGroundDiv.officeSettingsHeaderBkGroundDiv {
  background-color: $primary-red;
  height: 100%;
  width: 450px;
}

.-oneX-icon.drawer-icon.chevronDiv {
  background-color: $primary-red;
  color: $primary-white;
  height: 48px;
  width: 48px;
  display: block;
  transform: rotate(90deg);
  float: left;
  margin-top: 10px;
  margin-bottom: auto;
  border: none;
}

.-oneX-drawer-container officeSettingHeaderTextGroupDiv {
  color: $primary-white;
  margin: 15px;
}

.-oneX-drawer-container .officeSettingHeaderTextGroupDiv {
  height: max-content;
  color: $primary-white;
  padding-top: 10px;
  .-oneX-drawer-header {
    color: $primary-white;
    margin: 8px 15px 20px 15px;
    line-height: initial;
    padding: unset;
    font-size: 1.9em;
  }
  .sub-title-discription {
    padding: 15px;
  }
}

.officeSettingHeaderTitleSmall {
  color: $primary-white;
}

.textTemplateWarning {
  color: $primary-white;
  margin-left: 25px;
  margin-right: 25px;
  width: 90%;
}

.textTemplateHeaderTextGroupDiv {
  height: 100%;
  color: $primary-white;
  margin: 15px;
}

.oooDefaultText {
  margin: 15px;
  width: 90%;
}

.oooCustomText {
  margin: 15px;
  width: 90%;
}

.-oneX-icon.icon-in-drawer {
  background-color: $primary-red;
  color: $primary-white;
  height: 48px;
  width: 48px;
  display: block;
  float: left;
  margin: 10px;
  margin-bottom: auto;
}

.-oneX-panel-button {
  font-size: 22px;
}

.-oneX-fieldset {
  .-oneX-legend {
    font-size: 18px;
    color: $primary-black;
    margin-left: 2px;
  }
}

.panel-details {
  font-size: 14px;
}

.label-right {
  display: inline-block;
  width: max-content;
  position: relative;
}
