.image-container {
    position: absolute;
    width: 768px;
    height: 432px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    canvas {
        position: absolute;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
    }
    img {
        position: absolute;
        -webkit-transition:opacity .3s;
        transition:opacity .3s;
    }
}


@mixin loading-spinner($activeColor: #EF6565, $selector: "&::before", $time: 1.5s) {
    @keyframes spinner {
        0% {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }

        100% {
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
    }
    
    animation-play-state: running;
    opacity: 1;
    position: relative;
    
    &.-paused { 
        animation-play-state: paused; 
        opacity: 0.2;
        transition: opacity linear 0.1s;
    }

    #{$selector} {
        animation: $time linear infinite spinner;
        animation-play-state: inherit;
        border: solid 3px #dedede;
        border-bottom-color: #{$activeColor};
        border-radius: 50%;
        content: "";
        height: 40px;
        left: 50%;
        opacity: inherit;
        position: absolute;
        top: 50%; 
        transform: translate3d(-50%, -50%, 0);
        width: 40px;
        will-change: transform;
    }
}

.loading-spinner {
    @include loading-spinner;
    //height: 100vh;
}