$scrollbarBG: #cfd8dc;
$thumbBG: #90a4ae;

@mixin media_min_height() {
  @media screen and (min-height: 500px) {
    .no-messages,
    .all-messages {
      min-height: 30vh;
      max-height: 50vh;
    }
    .-sfc-message-index .-sfc-interaction-panel.-display-flex-col {
      min-height: 28vh;
      max-height: 28vh;
    }
  }
  @media screen and (min-height: 600px) {
    .no-messages,
    .all-messages {
      min-height: 60vh;
      max-height: 60vh;
    }
    .-sfc-message-index .-sfc-interaction-panel.-display-flex-col {
      min-height: 31vh;
      max-height: 31vh;
    }
  }
  @media screen and (min-height: 700px) {
    .no-messages,
    .all-messages {
      min-height: 63vh;
      max-height: 63vh;
    }
    .-sfc-message-index .-sfc-interaction-panel.-display-flex-col {
      min-height: 40vh;
      max-height: 40vh;
    }
  }
  @media screen and (min-height: 800px) {
    .no-messages,
    .all-messages {
      max-height: 65vh;
    }
    .-sfc-message-index .-sfc-interaction-panel.-display-flex-col {
      min-height: 49vh;
      max-height: 49vh;
    }
  }
  @media screen and (min-height: 900px) {
    .no-messages,
    .all-messages {
      max-height: 66vh;
    }
    .-sfc-message-index .-sfc-interaction-panel.-display-flex-col {
      min-height: 50vh;
      max-height: 50vh;
    }
  }
}

#empty-state {
  padding: 0 15px;
  margin: 0 0 30px 0;
  position: relative;
  & .-sfc-main-heading {
    text-align: center;
  }

  & p[class*="-oneX-heading"] {
    padding-top: 15px;
    padding-bottom: 10px;
    text-decoration: underline;
  }

  & p.-oneX-heading--h4 {
    margin-top: 15px;
    text-decoration: none;
  }

  li {
    margin-bottom: 3px;
  }

  #archiving-tab ul,
  #attachments-tab ul,
  #hintText ul,
  #general-help-tab ul {
    li {
      margin-left: 25px;
      margin-bottom: 5px;
      list-style: none;
    }
    li.sublist {
      margin-left: 40px;
      list-style: disc;
    }
    li.sublistNumber {
      margin-left: 40px;
      list-style: none;
    }
  }

  .-oneX-notification__text .empty-section {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 0;
    padding-top: 0;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    background-color: #000000;
    border-color: #6e6e6e;
    border: 1px solid #ffffff;
    text-align: center;
  }

  .display-button {
    display: inline-block;
    margin: auto;
    height: 35px;
    width: 60px;
    border-radius: 35px;
    position: relative;
    top: 9px;
    cursor: none;
    &:hover {
      background-color: $primary-merna-03-digital;
    }
  }
  .new-message-style {
    position: relative;
  }
  .new-message-icon {
    height: 24px;
    width: 24px;
    padding-top: 17px;
  }
  .red-icon-background {
    background-color: $primary-red;
    width: 36px;
    height: 36px;
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 9px;
  }
  .gear-icon {
    height: 36px;
    width: 36px;
    //position: absolute;
    display: inline-block;
  }
  .-oneX-icon .icon {
    fill: $primary-white;
  }
  .phone-image {
    padding-top: 18%;
  }
  .nestedLI {
    margin-left: 43px;
    font-style: italic;
  }
}

.texts {
  border-right: 1px solid #f3f3f3;
  .options-container {
    background-color: $primary-white;
    color: #222;
    min-height: 50px;
    .-oneX-btn.active {
      background-color: #00cc36;
    }
    ul {
      margin: 0;
      padding: 12px 0 0;
      align-self: center;
      color: #222;
      font-size: 12px;
      text-transform: uppercase;
      li {
        float: left;
        min-width: 33%;
        margin: 0 auto;
        text-align: center;
        a {
          text-decoration: none;
          color: #0a70bc;
          outline: none;
        }
      }
    }
    ul::after {
      content: "";
      clear: both;
      display: table;
    }
  }
}

.sfconnect-filter {
  margin-right: 10px;
  margin-bottom: 10px;
}

.send-message {
  border-top: 1px solid $primary-gray;
  .send-message-pane {
    margin: 20px -20px;
    &.keyboard {
      @media screen and (max-width: $grid-breakpoint-md) {
        //margin: 20px -20px;
        min-height: 600px;
        max-height: 700px;
      }
    }
    .-oneX-icon-container.-oneX-icon--interactive {
      position: absolute;
      width: fit-content;
      right: 5px;
      bottom: 10px;
      &.mobile-view {
        top: 80px;
        right: 5px;
      }
      .-oneX-icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .preview-pane {
    position: absolute;
    left: 16px;
    bottom: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    z-index: 99;
    div {
      margin: 0 2px 0 2px;
    }
    .preview-box {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 38px;
      padding: 1px;
      margin: 0;
      background: $primary-white;
      border: 1px $primary-gray-02 solid;
      border-radius: 3px;
      .file-info {
        display: flex;
        flex-direction: column;
        span {
          line-height: 16px;
          &.file-size {
            color: $primary-gray-01;
          }
        }
      }
    }
    .-oneX-icon-container.-oneX-icon--interactive {
      position: relative;
      right: 0;
      bottom: 0;
      .-oneX-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .-oneX-widget__err-text {
    display: none;
  }
  .bottom-nav {
    > ul {
      li {
        float: left;
        width: 33%;
        text-align: center;
      }
    }
  }
  .bottom-nav::after {
    content: "";
    clear: both;
    display: table;
  }
  .send-message-action {
    button {
      margin: 10% auto;
      height: 150px;
      position: relative;
      top: -3px;
      @media screen and (max-width: $grid-breakpoint-lg) {
        height: 40px;
        margin: 5% auto;
      }
      @media screen and (max-width: $grid-breakpoint-sm) {
        height: 50px;
      }
    }
  }
  .textbox {
    position: relative;
    border: 1px $primary-gray-02 solid;
    overflow: hidden;
    border-radius: 10px;
    min-width: 100%;
    height: 160px;
    textarea {
      position: relative;
      max-width: 100%;
      min-width: 100%;
      max-height: 112px;
      min-height: 112px;
      padding-right: 25px;
      box-shadow: none;
      &::-webkit-scrollbar {
        width: 11px;
      }
      & {
        scrollbar-width: thin;
        scrollbar-color: $thumbBG $scrollbarBG;
      }
      &::-webkit-scrollbar-track {
        background: $scrollbarBG;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $thumbBG;
        border-radius: 6px;
        border: 3px solid $scrollbarBG;
      }
    }
    .bottom-panel {
      position: relative;
      border-top: 1px $primary-gray-02 solid;
      height: 48px;
    }
  }
  input {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

body:not(.-oneX-user-tabbing)
  textarea.-oneX-textfield--conversational-input:focus {
  padding-right: 25px;
}

@include media_iPhone("X") {
  @include sendMessageFix();
}

.bottom-nav::after {
  content: "";
  clear: both;
  display: table;
}
.send-message-action {
  input {
    align-self: center;
    margin: 49% auto;
  }
}

.no-messages,
.all-messages {
  margin: auto;
  // padding-bottom: 30px;
  overflow-y: scroll;
  min-height: 60vh;
  max-height: 66vh;
  &::-webkit-scrollbar {
    width: 11px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: $thumbBG $scrollbarBG;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbarBG;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumbBG;
    border-radius: 6px;
    border: 3px solid $scrollbarBG;
  }
  .message-pane:nth-child(2n) {
    background-color: #f3f3f3;
  }

  /* START: Mobile Swipe Styles || OVERRIDE*/
  .-oneX-icon-container.-oneX-icon--interactive {
    padding: 5px;
  }
  .-sfc-archive,
  .-sfc-followup-flag {
    padding: 10px 5px;
    svg {
      height: 24px;
      width: 24px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
  }
  /* END: Mobile Swipe Styles  */
}

@include media_min_height();

.-sfc-message-index {
  overflow: unset;
  position: relative;
  button#closeMessagePanelX {
    width: 36px;
    height: 36px;
    right: 14px;
    top: 0px;
    span {
      width: 36px;
      height: 36px;
      border: #000 solid 1px;
    }
    &:focus {
      border: 0;
    }
    &:focus::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      right: -13px;
      bottom: -13px;
      outline: 5px auto #0073a8;
    }
  }
  .-oneX.attachment-error-banner {
    position: absolute;
    width: 100%;
    ul {
      list-style: disc inside;
    }
  }

  .client-display-container {
    min-height: 54px;
    max-height: 75px;
    background-color: #f3f3f3;
  }
  .client-alert-display-container {
    background-color: $primary-red;
    padding: 3px;
    text-align: center;
    color: $primary-white;
    @media screen and (max-device-width: $grid-breakpoint-md) {
      height: fit-content;
      font-size: 14px;
    }
    .add-prospect-anchor {
      color: $primary-white;
      //text-decoration: none;
      text-decoration-color: $primary-white;
    }
    select {
      padding-bottom: 8px;
    }
  }
  .client-name-display-container {
    background-color: $primary-gray-02;
    text-align: center;
    padding: 5px;
    color: $primary-black;
  }

  .-sfc-interaction-panel {
    overflow-y: scroll;
    min-height: 48vh;
    max-height: 48vh;
    padding-bottom: 3px;
    &::-webkit-scrollbar {
      width: 11px;
    }
    & {
      scrollbar-width: thin;
      scrollbar-color: $thumbBG $scrollbarBG;
    }
    &::-webkit-scrollbar-track {
      background: $scrollbarBG;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $thumbBG;
      border-radius: 6px;
      border: 3px solid $scrollbarBG;
    }
    message-pane:focus {
      border: solid 1px black;
    }
    .message-pane:nth-child(2n) {
      background-color: #f3f3f3;
    }
    /*Move the Agent Phone Number inline with customer number.*/
    .agent p.message-date {
      position: relative;
      left: 43px;
    }
    @media screen and (max-width: $grid-breakpoint-lg) {
      min-height: 40vh;
      max-height: 40vh;
    }
    .-sfc-attachment {
      width: 100%;
      position: relative;
    }
    .message-delivery {
      color: #505050;
      padding-right: 80px;
      float: right;
      font-size: 12px;
      text-transform: capitalize;
      transition: transform 0.5s;
      transform: translate3d(0, -100%, 0);
      &.status-sending {
        color: $primary-charcoal-01-digital;
        transition: transform 2s;
        transform: translate3d(0, 20%, 0);
      }
      &.status-sent {
        color: nth($accent-life-green, 1);
        transition: transform 3s;
        transform: translate3d(0, -100%, 0);
      }
      &.status-failed {
        color: $primary-merna-03;
        transition: transform 3s;
        transform: translate3d(0, -100%, 0);
      }
      &.status-delayed {
        color: $primary-charcoal-01-digital;
        //color: $accent-tangerine;
        transition: transform 3s;
        transform: translate3d(0, -100%, 0);
      }
    }
  }
}

.multiMatchDropDown {
  font-size: "16px";
}
