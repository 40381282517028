.filterPop {
    padding: 5px 5px 5px 0px;
}
.svg-size {   
    width: 20px;
    height: 20px;
    .circle-styling {
        cx: 10;
        cy: 10;
        r: 5;
        stroke: $primary-error;
        stroke-width: 1;
        fill: $primary-error;
        z-index: 10;
    }
}