.search-clear-button {
  right: 0px;
  top: 0px;
  display: inline-block;
  margin-right: 5px;
}

.search-submit-button {
  right: 40px;
  top: 0px;
  display: inline-block;
  border-right: none;
  border-radius: 0;
}


.header-new-message {
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 20px;
}

.filter-clear-button {
  display: inline-block;
  border: none;
  //padding-left: 2px;
}

.search-magnifying-glass {
  display: inline;
  position: relative;
  //border: none;
}

.button-size{
  width: fit-content;
}

