
@media print {
    body {
       font: 12pt Georgia, "Times New Roman", Times, serif;
       line-height: 1.3;
       overflow: auto;
       min-height: 700vh;
       max-height: fit-content;
    }
   header .header-row div[class*="-oneX-col"]:nth-child(4) {
       display: none;
   }
   .texts, .bread-crumb {
       /*  IN-TRAY  */
       display: none; 
   }
    .-sfc-message-index {
          width:100%;
          height: 100%;
          .client-display-container {
            min-height: 1000vh;
            max-height: 1000vh;
          }
          .-sfc-interaction-panel {
            overflow: auto ! important;
            min-height: 1000vh ! important;
             max-height: 1000vh ! important;
            padding-bottom: 3px;
        }
        .send-message-pane{
            display: none;
        }
    }
   footer {
       position: initial;
       margin-top: 50px;
   }
}