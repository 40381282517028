@mixin expand-icon {
    transition-delay: 0ms;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.emoji-container{
    font-family: "intercom-font", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline-block;
    width:100%;
    @media screen and (max-width:500px){
        position: relative;
        top: 1px;
    }
    .sfconnect-composer-popover-input{
        font-size-adjust: none;
        font-size: 100%;
        font-style: normal;
        letter-spacing: normal;
        font-stretch: normal;
        font-variant: normal;
        font-weight: 400;
        font: normal normal 100% "intercom-font", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-align: left;
        text-align-last: auto;
        text-decoration: none;
        -webkit-text-emphasis: none;
        text-emphasis: none;
        text-height: auto;
        text-indent: 0;
        text-justify: auto;
        text-outline: none;
        text-shadow: none;
        text-transform: none;
        text-wrap: normal;
        alignment-adjust: auto;
        alignment-baseline: baseline;
        -webkit-animation: none 0 ease 0 1 normal;
        animation: none 0 ease 0 1 normal;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        -webkit-appearance: normal;
        -moz-appearance: normal;
        appearance: normal;
        azimuth: center;
        -webkit-backface-visibility: visible;
        backface-visibility: visible;
        background: none 0 0 auto repeat scroll padding-box transparent;
        background-color: transparent;
        background-image: none;
        baseline-shift: baseline;
        binding: none;
        bleed: 6pt;
        bookmark-label: content();
        bookmark-level: none;
        bookmark-state: open;
        bookmark-target: none;
        border: 0 none transparent;
        border-radius: 0;
        bottom: auto;
        box-align: stretch;
        -webkit-box-decoration-break: slice;
        box-decoration-break: slice;
        box-direction: normal;
        box-flex: 0.0;
        box-flex-group: 1;
        box-lines: single;
        box-ordinal-group: 1;
        box-orient: inline-axis;
        box-pack: start;
        box-shadow: none;
        box-sizing: content-box;
        -webkit-column-break-after: auto;
        break-after: auto;
        -webkit-column-break-before: auto;
        break-before: auto;
        -webkit-column-break-inside: auto;
        break-inside: auto;
        caption-side: top;
        clear: none;
        clip: auto;
        color: inherit;
        color-profile: auto;
        -webkit-column-count: auto;
        -moz-column-count: auto;
        column-count: auto;
        -webkit-column-fill: balance;
        -moz-column-fill: balance;
        column-fill: balance;
        -webkit-column-gap: normal;
        -moz-column-gap: normal;
        column-gap: normal;
        -webkit-column-rule: medium medium #1f1f1f;
        -moz-column-rule: medium medium #1f1f1f;
        column-rule: medium medium #1f1f1f;
        -webkit-column-span: 1;
        -moz-column-span: 1;
        column-span: 1;
        -webkit-column-width: auto;
        -moz-column-width: auto;
        column-width: auto;
        -webkit-columns: auto auto;
        -moz-columns: auto auto;
        columns: auto auto;
        content: normal;
        counter-increment: none;
        counter-reset: none;
        crop: auto;
        cursor: auto;
        direction: ltr;
        display: inline;
        dominant-baseline: auto;
        drop-initial-after-adjust: text-after-edge;
        drop-initial-after-align: baseline;
        drop-initial-before-adjust: text-before-edge;
        drop-initial-before-align: caps-height;
        drop-initial-size: auto;
        drop-initial-value: initial;
        elevation: level;
        empty-cells: show;
        fit: fill;
        fit-position: 0 0;
        float: none;
        float-offset: 0 0;
        grid-columns: none;
        grid-rows: none;
        hanging-punctuation: none;
        height: auto;
        hyphenate-after: auto;
        hyphenate-before: auto;
        hyphenate-character: auto;
        hyphenate-lines: no-limit;
        hyphenate-resource: none;
        -webkit-hyphens: manual;
        -ms-hyphens: manual;
        hyphens: manual;
        icon: auto;
        image-orientation: auto;
        image-rendering: auto;
        image-resolution: normal;
        inline-box-align: last;
        left: auto;
        line-height: inherit;
        line-stacking: inline-line-height exclude-ruby consider-shifts;
        list-style: disc outside none;
        margin: 0;
        marks: none;
        marquee-direction: forward;
        marquee-loop: 1;
        marquee-play-count: 1;
        marquee-speed: normal;
        marquee-style: scroll;
        max-height: none;
        max-width: none;
        min-height: 0;
        min-width: 0;
        move-to: normal;
        nav-down: auto;
        nav-index: auto;
        nav-left: auto;
        nav-right: auto;
        nav-up: auto;
        opacity: 1;
        orphans: 2;
        outline: medium none invert;
        outline-offset: 0;
        overflow: visible;
        overflow-style: auto;
        padding: 0;
        page: auto;
        page-break-after: auto;
        page-break-before: auto;
        page-break-inside: auto;
        page-policy: start;
        -webkit-perspective: none;
        perspective: none;
        -webkit-perspective-origin: 50% 50%;
        perspective-origin: 50% 50%;
        pointer-events: auto;
        position: static;
        presentation-level: 0;
        punctuation-trim: none;
        quotes: none;
        rendering-intent: auto;
        resize: none;
        right: auto;
        rotation: 0;
        rotation-point: 50% 50%;
        ruby-align: auto;
        ruby-overhang: none;
        ruby-position: before;
        ruby-span: none;
        size: auto;
        string-set: none;
        table-layout: auto;
        top: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        -webkit-transform-origin: 50% 50% 0;
        -ms-transform-origin: 50% 50% 0;
        transform-origin: 50% 50% 0;
        -webkit-transform-style: flat;
        transform-style: flat;
        transition: all 0 ease 0;
        unicode-bidi: normal;
        vertical-align: baseline;
        white-space: normal;
        white-space-collapse: collapse;
        widows: 2;
        width: auto;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        z-index: auto;
        text-align: start;
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(enabled=false)";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .sfconnect-composer-popover {
        z-index: 2147483003;
        position: absolute;
        bottom: 50px;
        right: calc(50% - 165px);
        box-shadow: 0 1px 15px 1px rgba(0,0,0,.08);
        background-color: $primary-white;
        border-radius: 6px;
        transition-duration: 200ms;
        transition-delay: 0ms;
        transform-style: flat;
        transform-origin: 50% 50% 0;
        opacity: 0;
        transition: all 0.2s linear;
        visibility: hidden;
    }
    .sfconnect-composer-popover.active{
        visibility: visible;
        opacity:1;
        bottom: 45px;
        right: 75px;
        @media screen and (max-width:988px){
            right: -20%
        }
    }
    .sfconnect-emoji-picker {
        width: 330px;
        height: 192px;  /*260px;*/
    }
    .sfconnect-composer-popover-header {
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        height: 40px;
        border-bottom: 1px solid #edeff1;
    }
    .sfconnect-composer-popover-input {
        background-image: '../images/search.png';
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 0 12px;
        font-weight: 400;
        font-size: 14px;
        color: #6e7a89;
        padding-left: 25px;
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        background-image: '../images/search.png';
        border:none;
        outline: none;
    }
    .sfconnect-composer-popover-body {
        position: absolute;
        /* top: 40px;  //Add when search is enabled */
        left: 0;
        right: 0;
        bottom: 5px;
        padding: 2px 20px;
        overflow-y: hidden; /*scroll;*/
        background-color: white;
    }

    .sfconnect-emoji-picker-group {
        margin: 10px -5px;
    }
    .sfconnect-emoji-picker-group-title {
        color: $primary-black;
        font-weight: 400;
        font-size: 14px;
        margin: 5px;
    }

    .sfconnect-emoji-picker-emoji {
        padding: 5px;
        width: 30px;
        line-height: 30px;
        display: inline-table;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        font-size: 28px;
        transition: -webkit-transform 60ms ease-out;
        transition: transform 60ms ease-out;
        transition: transform 60ms ease-out,-webkit-transform 60ms ease-out;
        transition-delay: 60ms;
        font-family: Apple Color Emoji,Segoe UI Emoji,NotoColorEmoji,Segoe UI Symbol,Android Emoji,EmojiSymbols;
    }

    .sfconnect-emoji-picker-emoji {
        &:focus {
            @include expand-icon;
            outline: 1px solid $primary-black;
        }
        &:hover,
        &:active {
            @include expand-icon;
        }
    }
  
    .sfconnect-composer-popover-caret {
        position: absolute;
        bottom: -8px;
        right: 0;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $primary-white;
        left:20px;
    }

   
    .chat-input-tool{
        // padding: 10px;
        // width: 50px;
        // border-radius: 5px;
        // position: absolute;
        // bottom:20px;
        // left: 50%;
        // outline:none;
        display: inline-block;
        &.active {
            background-color: red;
        }
    }
    .emoji-panel .chat-input-tool:before {
            content: "🙂";
            font-size: 20px;
            text-align: center;
            position: relative;
            top: 2px;
        @media screen and (max-width:988px){
            font-size: 14px;
        }
    }

}